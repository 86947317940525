.impressum {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
}

.impressum__content {
    width: 60vw;
    background-color: #fff;
    border-radius: 0.25rem;
    color: rgb(70, 69, 69);
    height: 90vh;
}

.impressum__header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.impressum__title {
    margin: 0;
}

.impressum__body {
    padding: 20px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    max-height: 75vh;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.impressum__section {
    margin-top: 20px;
    margin-bottom: 40px;
}

.impressum__section p {
    padding: 10px 0;
}


.impressum__header> svg {
    font-size: 1.5rem;
  }
  
.impressum__header > svg:hover {
cursor: pointer;
}