.home {
  /*background-image: linear-gradient(45deg, #303e50, #4e698b);*/
  background-image: url("../assets/img/headway-5QgIuuBxKwM-unsplash.jpg");
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__overlay {
  /*background-color: rgba(33, 40, 45, 0.7);*/
  background-color: rgba(48, 62, 80, 0.7);
  z-index: 200;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home__hero {
  color: white;
  text-align: center;
  padding: 0 15px;
}

.home__hero > h1 {
  font-size: 5rem;
  margin-bottom: 40px;
  animation-name: fade-in;
  animation-duration: 2s;
  text-transform: uppercase;
  line-height: 1;
}

.home__hero > h3 {
  font-size: 1.4rem;
  font-weight: 600;
  animation-name: fade-in-from-top;
  animation-duration: 2s;
}

.home__hero > h2 {
  font-size: 2.4rem;
  font-weight: 200;
  animation-name: fade-in-from-left;
  animation-duration: 2s;
  text-transform: uppercase;
}

.home__socials {
  padding: 0;
}

.home__scrolldown > svg {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 45px;
  width: 45px;
  font-size: 45px;
  line-height: 45px;
  color: #fff;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.home__scrolldown > svg:hover {
  transform: scale(1.1);
}

@keyframes fade-in-from-top {
  from {
    opacity: 0;
    transform: translateY(-5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}

@keyframes fade-in-from-left {
  from {
    opacity: 0;
    transform: translateX(-5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 992px) {
 
}


@media screen and (max-width: 688px) {
  h1 {
    font-size: 3rem !important;
    line-height: 4rem !important;
  }

  h2 {
    font-size: 1.8rem !important;
  }
}