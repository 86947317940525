@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
* {
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
}

.home {
  /*background-image: linear-gradient(45deg, #303e50, #4e698b);*/
  background-image: url(/static/media/headway-5QgIuuBxKwM-unsplash.a6ad39b8.jpg);
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__overlay {
  /*background-color: rgba(33, 40, 45, 0.7);*/
  background-color: rgba(48, 62, 80, 0.7);
  z-index: 200;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home__hero {
  color: white;
  text-align: center;
  padding: 0 15px;
}

.home__hero > h1 {
  font-size: 5rem;
  margin-bottom: 40px;
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  text-transform: uppercase;
  line-height: 1;
}

.home__hero > h3 {
  font-size: 1.4rem;
  font-weight: 600;
  -webkit-animation-name: fade-in-from-top;
          animation-name: fade-in-from-top;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.home__hero > h2 {
  font-size: 2.4rem;
  font-weight: 200;
  -webkit-animation-name: fade-in-from-left;
          animation-name: fade-in-from-left;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  text-transform: uppercase;
}

.home__socials {
  padding: 0;
}

.home__scrolldown > svg {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 45px;
  width: 45px;
  font-size: 45px;
  line-height: 45px;
  color: #fff;
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
}

.home__scrolldown > svg:hover {
  transform: scale(1.1);
}

@-webkit-keyframes fade-in-from-top {
  from {
    opacity: 0;
    transform: translateY(-5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}

@keyframes fade-in-from-top {
  from {
    opacity: 0;
    transform: translateY(-5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}

@-webkit-keyframes fade-in-from-left {
  from {
    opacity: 0;
    transform: translateX(-5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
}

@keyframes fade-in-from-left {
  from {
    opacity: 0;
    transform: translateX(-5rem);
  }
  to {
    opacity: 1;
    transform: translateX(0rem);
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 992px) {
 
}


@media screen and (max-width: 688px) {
  h1 {
    font-size: 3rem !important;
    line-height: 4rem !important;
  }

  h2 {
    font-size: 1.8rem !important;
  }
}
.header {
  width: 100%;
  background: none;
}

.header__wrapper {
  padding: 20px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__left > img {
  height: 35px;
}

.header__right > svg {
  font-size: 1.5rem;
}

.header__right {
  cursor: pointer;  
}


@media screen and (max-width: 992px) {
 
}


@media screen and (max-width: 688px) {
  .header__wrapper {
    padding: 15px 15px;
  }
}
.stickyHeader {
  position: fixed;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid lightgray;
  -webkit-animation: moveDown 0.5s ease-in-out;
          animation: moveDown 0.5s ease-in-out;
  z-index: 100;
}

.stickyHeader__wrapper {
  padding: 10px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stickyHeader__left > img {
  height: 30px;
}

.stickyHeader__right > svg {
  font-size: 1.5rem;
}

.stickyHeader__right > svg:hover {
  cursor: pointer;
}

@-webkit-keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

.impressum {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
}

.impressum__content {
    width: 60vw;
    background-color: #fff;
    border-radius: 0.25rem;
    color: rgb(70, 69, 69);
    height: 90vh;
}

.impressum__header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.impressum__title {
    margin: 0;
}

.impressum__body {
    padding: 20px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    max-height: 75vh;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.impressum__section {
    margin-top: 20px;
    margin-bottom: 40px;
}

.impressum__section p {
    padding: 10px 0;
}


.impressum__header> svg {
    font-size: 1.5rem;
  }
  
.impressum__header > svg:hover {
cursor: pointer;
}
