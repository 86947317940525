.stickyHeader {
  position: fixed;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid lightgray;
  animation: moveDown 0.5s ease-in-out;
  z-index: 100;
}

.stickyHeader__wrapper {
  padding: 10px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stickyHeader__left > img {
  height: 30px;
}

.stickyHeader__right > svg {
  font-size: 1.5rem;
}

.stickyHeader__right > svg:hover {
  cursor: pointer;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}
