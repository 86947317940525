.header {
  width: 100%;
  background: none;
}

.header__wrapper {
  padding: 20px 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__left > img {
  height: 35px;
}

.header__right > svg {
  font-size: 1.5rem;
}

.header__right {
  cursor: pointer;  
}


@media screen and (max-width: 992px) {
 
}


@media screen and (max-width: 688px) {
  .header__wrapper {
    padding: 15px 15px;
  }
}